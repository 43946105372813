/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./00_utilities//style/index.scss";
import Footer from "./organisms/Footer";
import styled from 'styled-components'
// import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const StyledOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -100;
    // z-index: 10;
    background: rgba( 0, 0, 0, .95);
    left: 0;
    top: 0;
    opacity: 0;
    // pointer-events: none;

    &.active {
      z-index: 10;
      opacity: 1;
      // pointer-events: initial;
    }
  `;

  const PerformerModal = styled.div`
    position: fixed;
    z-index: -101;
    opacity: 0;
    background: white;
    width: 80vw;
    height: 80vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &.active {
      z-index: 11;
      opacity: 1;
    }
  `;

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <PerformerModal id="performer_modal" />
        <StyledOverlay id="main_overlay" />
        <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
