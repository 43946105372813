import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from './atoms/Logo';
import Nav from './molecules/Nav';

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginTop: '40px',
      position: 'absolute',
      width: '100%',
      zIndex: '3',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        margin: '0 auto',
      }}
    >

      <Logo />
      {/* <Nav /> */}

    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header