import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(props => <Link {...props } /> )`
    color: #fff;
    font-family: "Gilda Display", serif;
    font-size: 24px;
    text-decoration: none;
    margin-left: 50px;
    font-weight: 600;
`;

const LinkWrapper = styled.nav`

    @media screen and (max-width: 767px ) {
        display: none;
    }
`;

export default class Nav extends Component {
    render() {
        return (
            <LinkWrapper>
                <StyledLink to='/about/'>About</StyledLink>
                <StyledLink to='/faq/'>FAQ</StyledLink>
                <StyledLink to="/get-a-quote/">Get a Quote</StyledLink>
            </LinkWrapper>
        )
    }
}
