import React, { Component } from 'react';
import { Link } from 'gatsby';
import CCLogo from '../../images/cce-logo.svg';

export default class logo extends Component {
    render() {
        return (
            <Link
                to="/"
                className="a-logo"
            >
                <CCLogo />
            </Link>
        )
    }
}
