import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BASE from '../00_utilities/base';
import Facebook from '../../images/facebook.svg'
import Instagram from '../../images/instagram.svg'
import Twitter from '../../images/twitter.svg'

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #000022;
    padding: 58px 0;

    p {
        width: 80%;
        text-align: center;
        font-size: 12px;
        font-family: ${BASE.FONTS.heebo};
        color: #fff;
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid #fff;
    }
`;

const StyledNav = styled.ul`
    display: flex;

    a {
        color: #fff;
        text-decoration: none;
        font-size: 24px;
        font-family: ${BASE.FONTS.lapture};
        font-weight: 700;

        &:hover {
            color: #51557D;
        }

        &:not(:last-child) {
            margin-right: 51px;
        }
    }
`;

const StyledSocialMedia = styled.div`
    display: flex;
    a {
        font-size: 20px;
        color: #FF2E16;
        text-decoration: none;
        border: 2px solid #FF2E16;
        height: 29px;
        width: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
`;


export default class Footer extends Component {
    render() {
        return (
            <StyledFooter>
                {/* <StyledNav>
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/faq">FAQ</Link>
                    <Link to="/get-a-quote">Get a Quote</Link>
                </StyledNav> */}
                <StyledSocialMedia>
                    <a href="https://www.facebook.com/ChandelierCircus/"><Facebook /></a>
                    <a href="https://www.instagram.com/chandeliercircus/"><Instagram /></a>
                </StyledSocialMedia>
                <p>© Copyright 2022 Chandelier Circus Entertainment, LLC. All rights reserved. </p>
            </StyledFooter>
        )
    }
}
