const BASE = {
    'COLORS': {
        'yellow': '#FFB405',
        'purple': '#060D49',
        'blue': '#2C27FF',
        'blueLight': '#2FB7DB',
        'red': '#FF2E16',
        'white': '#ffffff',
    },
    'FONTS': {
        'heebo': `'Heebo', sans-serif`,
        'lapture': `'Gilda Display', serif`,
    }
}

export default BASE;